<template>
  <div class="bee-bus-annual-pass mb-3">
    <b-button
        class="w-100 btn btn-warning"
        @click="() => showDialog = true"
    >
      Notice - 5th September 2024 - Bee Bus Annual Pass! ... [Read More]
    </b-button>
    <b-modal
        v-model="showDialog"
        title="Notice - 5th September 2024"
        size="lg"
        scrollable
        ok-only
        ok-title="Close"
    >
      <h3>Bee Bus Annual Pass</h3>
      <p>
        Cash Box is delighted to confirm we are working with TfGM to provide the new Bee Bus Annual Pass to cut the cost of travel across Greater Manchester.
      </p>
      <p>
        With four months to go until all buses in Greater Manchester come under local control, the Mayor of Greater Manchester has today confirmed plans for a new annual bus pass that will cut the cost of travel.
      </p>
      <p>
        Available from 5 January 2025 – subject to agreement by the Greater Manchester Combined Authority – and costing £800, the Bee Bus Annual pass will be up to £240 (23%) cheaper over a year compared with 28-day tickets.
      </p>
      <p>
        Customers will be able to spread the payment, at no extra cost, throughout the year with a Credit Union loan – paying £66.67 per month or £15.36 per week, compared with £80 for a 28-day pass or £20 for a 7-day pass
      </p>
      <p class="font-weight-bold">
        TfGM is working with all Greater Manchester Credit Unions to open applications from 5th December so that passes are ready to use from 5th January.
      </p>
      <blockquote class="blockquote p-3 border rounded">
        The Greater Manchester Consortium of Credit Unions is delighted to be involved in this innovative new partnership with Transport for Greater Manchester. Credit Unions are not-for-profit financial co-operatives that have been providing access to affordable financial services in communities and workplaces in GM for over 30 years. This new product will spread the cost of travel and save people money which is just one of the ways in which credit unions can help make your money go further.
        <footer class="blockquote-footer pt-2">Clara Davies - <cite>Executive Lead for the Greater Manchester Consortium of Credit Unions</cite></footer>
      </blockquote>
      <p>
        TfGM is also working with credit unions to offer customers the chance to spread the cost of existing Metrolink products or an annual bus and tram pass starting in March 2025.
      </p>
      <p>
        If you’re not already a member of Cash Box this is a <a href="https://cashbox.cuaccount.com/join/" target="_blank">great time to join!</a>
      </p>
      <template #modal-footer>
        <a href="https://cashbox.cuaccount.com/join/" target="_blank" class="btn btn-success">Join CashBox</a>
        <b-button @click="showDialog = false">Close</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "BeeBusAnnualPass",
  data() {
    return {
      showDialog: false,
    }
  }
}
</script>

<style>
</style>